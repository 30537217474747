.flex {
  flex: 1 1 auto;
}

.black-overlay {
  position: relative;
  top: 0;
  right: 0;

  &:after {
    display: block;
    content: "";
    background-color: fade(@black, 40%);
    height: 100%;
    width: 100%;
    position: absolute;
    transition: @transition--duration;
  }
}

.drop-effect {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: inset 10px 10px 10px fade(@black, 15%), 0 5px 10px fade(@white, 15%), 5px 0 30px fade(@white, 15%), inset -10px -10px 15px fade(@white, 90%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @gray--light;

  @media (max-width: @screen__small--xs) {
    width: 40px;
    height: 40px;
    ion-icon {
      font-size: 1.3rem;
    }
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 16px;
    left: 7px;
    width: 13%;
    height: 13%;
    border-radius: 50%;
    background: shade(@white, 10%);
  }

  &:after {
    top: 7px;
    left: 20px;
    width: 8%;
    height: 8%;
  }

}

.hide {
  display: none;
}
