@import "../../node_modules/@fortawesome/fontawesome-free/less/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/less/brands";

@font-face {
  font-family: @primary__font--name;
  src: url(@primary__font__path--regular);
  src: url(@primary__font__path--regular) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: @primary__font--name;
  src: url(@primary__font__path--bold);
  src: url(@primary__font__path--bold) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: @primary__font--name;
  src: url(@primary__font__path--medium);
  src: url(@primary__font__path--medium) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: @secondary__font--name;
  src: url(@secondary__font__path--regular);
  src: url(@secondary__font__path--regular) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: @secondary__font--name;
  src: url(@secondary__font__path--bold);
  src: url(@secondary__font__path--bold) format("truetype");
  font-weight: bold;
}

.font {
  &--secondary {
    font-family: @secondary__font--name;
  }
}

.icon {
  .fa-icon;
  .fab;
  font-size: 1.8rem;
  color: fade(@gray, 80%);

  &.angular {
    &:before {
      content: @fa-var-angular;
    }
  }

  &.js {
    &:before {
      content: @fa-var-js;
    }
  }

  &.wordpress {
    &:before {
      content: @fa-var-wordpress;
    }
  }

  &.html5 {
    &:before {
      content: @fa-var-html5;
    }
  }

  &.css3 {
    &:before {
      content: @fa-var-css3;
    }
  }
}
