.section {
  height: 100%;
  width: 100%;
  min-width: 250px;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 3;
    padding: 0 0 0 5%;
    color: @white;
    @media (max-width: @screen__small) {
      width: 100%;
    }

    h1 {
      @font-size: 4.5vw;
      margin-bottom: 0.2rem;
      font-size: @font-size;
      white-space: nowrap;

      span {
        &.text-animation {
          font-size: 5vw;
          font-family: @secondary__font--name;
        }

        white-space: nowrap;
      }

      @media (max-width: @screen__small) {
        font-size: 2.5rem;
        span.text-animation {
          font-size: 3.5rem;
        }
      }
    }

    h2 {
      font-size: 1.5vw;
      margin-bottom: 1rem;
      @media (max-width: @screen__small) {
        font-size: 1rem;
      }
    }

    p {
      margin-top: 1vw;
      max-width: 300px;
      line-height: 1.3rem;
    }

    button {
      max-width: 150px;
      margin-top: 3rem;
      @media (max-width: @screen__small--xs) {
        width: 100%;
        max-width: 100%;
      }
    }

    &__logos {
      display: flex;
      margin-top: 1rem;

      .drop-effect {
        margin-right: 2rem;
        @media (max-width: @screen__small--xs) {
          margin-right: 1rem;
        }

        &:hover {
          transform: scale(0.8);
          transition: 0.2s;
        }
      }
    }

    @media (max-width: @screen__small--xs) {
      padding-left: 8%;
      padding-right: 8%;
    }
  }
}

.profile__pic {
  top: 0;
  right: 0;
  transition: 1000ms;
  height: 100%;
  position: absolute;
  filter: opacity(80%);

  &:hover {
    filter: opacity(100%);
  }

  @media (max-width: @screen__small--xs) {
    right: -30%;
  }

  &--wrapper {
    height: 100%;
    width: 100%;
    min-width: 200px;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    pointer-events: none;
  }

}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: @white;
  text-align: center;
  padding: 2rem 0;

  p {
    position: relative;
  }

  .waves {
    position: absolute;
    width: 100%;
    height: 5vh;
    min-height: 50px;
    max-height: 150px;
    bottom: 0;
    left: 0;

    .parallax > use {
      animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;

      &:nth-child(1) {
        animation-delay: -12s;
        animation-duration: 37s;
        fill: fade(@waves--color, 70%);
      }

      &:nth-child(2) {
        animation-delay: -13s;
        animation-duration: 40s;
        fill: fade(@waves--color, 50%);
      }

      &:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 43s;
        fill: fade(@waves--color, 30%);
      }

      &:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 50s;
        fill: @waves--color;
      }
    }
  }
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }

  h1 {
    font-size: 24px;
  }
}
