* {
  box-sizing: border-box;
}

body, html {
  font-family: @primary__font--name;
  font-size: 13px;
  height: 100%;
  width: 100%;
  background-color: @black;
}

h1 {
  font-size: 2rem;
}

button {
  border: 1px solid @border--color;
  color: @white;
  padding: 0.8rem;
  width: 100%;
  background-color: transparent;
  transition: @transition--duration;
  pointer-events: all;

  &:hover, &.active {
    box-shadow: 0 10px 65px 10px @primary__color;
    cursor: pointer;
  }
}
